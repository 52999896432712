import { MenuLink } from '../MenuLink/MenuLink.component';
import React, { ComponentPropsWithRef, FC, PropsWithChildren, useMemo } from 'react';
import { useWeatherLink } from '@meteo/business/meteoUrl.utils';
import { SettingsTabEnum } from '@settings/business/Settings.type';
import { StocksHomeTabs } from '@stocks/business/Stocks.types';
import { usePlcRouter } from '@utils/customHooks';
import { getLinkProps, getPreferencesLinkProps, urlEnum } from '@utils/url';
import classnames from "classnames";

interface Props extends ComponentPropsWithRef<'nav'> {
  isDark: boolean;
  isMobile?: boolean;
  isSSO?: boolean;
}

const Navigation: FC<PropsWithChildren<Props>> = ({ isDark, className, children, isSSO, isMobile = false, ...rest }) => {
  const { route } = usePlcRouter();
  const isSSOclassname = 'is-sso space-between align-center width-100';
  const rootClassName = classnames('navigation', 'flex', isDark ? 'isDark' : '', isSSO ? isSSOclassname : '', className);
  const menuLinkClassName = 'header-navigation';
  const variant = isMobile ? 'h5' : undefined;
  const meteoLinkProps = useWeatherLink(urlEnum.meteo);
  const radarLinkProps = useWeatherLink(urlEnum.meteoRadar);
  const hourByHourLinkProps = useWeatherLink(urlEnum.hourByHour);
  const treatmentScheduleLinkProps = useWeatherLink(urlEnum.treatmentSchedule);
  const meteoPreferencesLinkProps = useMemo(() => getPreferencesLinkProps(SettingsTabEnum.forecast), []);

  return (
    <nav className={rootClassName} {...rest}>
      {isSSO ? (
        <>
          <div className='flex row'>
            <MenuLink
              className={menuLinkClassName}
              i18nKey="common.the-weather"
              isDark={isDark}
              linkProps={meteoLinkProps}
              variant={variant}
            />
            <MenuLink
              className={menuLinkClassName}
              i18nKey="meteo.radar.breadcrumb"
              isDark={isDark}
              linkProps={radarLinkProps}
              variant={variant}
            />
            <MenuLink
              className={menuLinkClassName}
              i18nKey="meteo.hour-by-hour.menu-title"
              isDark={isDark}
              linkProps={hourByHourLinkProps}
              variant={variant}
            />
            <MenuLink
              className={menuLinkClassName}
              i18nKey="treatment-schedule.page.title"
              isDark={isDark}
              linkProps={treatmentScheduleLinkProps}
              variant={variant}
            />
            <MenuLink
              className={menuLinkClassName}
              i18nKey="services.dryingHerbs.menu-title"
              isDark={isDark}
              linkProps={{ as: urlEnum.herbDrying, href: urlEnum.herbDrying }}
              variant={variant}
            />
          </div>
          <MenuLink
            className={menuLinkClassName}
            i18nKey="preferences.menu-title.meteo-preferences"
            isDark={isDark}
            linkProps={meteoPreferencesLinkProps}
            variant={variant}
          />
        </>
      ) : (
        <>
          <MenuLink
            active={urlEnum.journal === route}
            className={menuLinkClassName}
            i18nKey="common.newspaper"
            isDark={isDark}
            linkProps={{ as: urlEnum.journal, href: urlEnum.journal }}
            variant={variant}
          />
          <MenuLink
            active={route !== urlEnum.journal && (urlEnum.vraijournal.includes(route) || route.includes(urlEnum.actu))}
            className={menuLinkClassName}
            i18nKey="common.journal"
            isDark={isDark}
            linkProps={{ as: urlEnum.vraijournal, href: urlEnum.vraijournal }}
            variant={variant}
          />
          <MenuLink
            active={RegExp(urlEnum.meteo).test(route)}
            className={menuLinkClassName}
            i18nKey="common.the-weather"
            isDark={isDark}
            linkProps={meteoLinkProps}
            variant={variant}
          />
          <MenuLink
            active={RegExp(urlEnum.stocks).test(route)}
            className={menuLinkClassName}
            i18nKey="common.stocks.menu-title"
            isDark={isDark}
            linkProps={getLinkProps(`${urlEnum.stocks}/[stockType]`, { stockType: StocksHomeTabs.Crops })}
            variant={variant}
          />
          <MenuLink
            active={RegExp(urlEnum.services).test(route)}
            className={menuLinkClassName}
            i18nKey="common.services.menu-title"
            isDark={isDark}
            linkProps={{ as: urlEnum.services, href: urlEnum.services }}
            variant={variant}
          />
          <MenuLink
            active={RegExp(urlEnum.innovations).test(route)}
            className={menuLinkClassName}
            i18nKey="common.innovations.menu-title"
            isDark={isDark}
            linkProps={{ as: urlEnum.innovations, href: urlEnum.innovations }}
            variant={variant}
          />
          <MenuLink
            active={RegExp(urlEnum.guides).test(route)}
            className={menuLinkClassName}
            i18nKey="common.guides"
            isDark={isDark}
            linkProps={{ as: urlEnum.guides, href: urlEnum.guides }}
            variant={variant}
          />
        </>
      )}
      {children}
    </nav>
  );
};

export { Navigation };
